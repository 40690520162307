import React, { useState } from 'react';
import './FAQ.css';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
      <h2>
        {question} 
        <span className="faq-icon">{isOpen ? '-' : '+'}</span>
      </h2>
      {isOpen && <p>{answer}</p>}
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="faq-container">
      <img src={`${process.env.PUBLIC_URL}/header.jpg`} alt="Pro-Life Crusade Header" className="header-image" />
      <h1>Pro-Life Crusade - FAQs</h1>
      
      <FAQItem 
        question="Why another pro-life organization?" 
        answer={`There are many pro-life organizations that are doing great work in the realm of Catholic social action: convincing women not to abort their children, helping pregnant women, promoting the faithfulness of fathers, encouraging adoption, supporting repentant women who have committed abortion in the past, etc. But one thing that’s lacking is a strong call for legislation to criminalize abortion. Most pro-life organizations are lacking in assertiveness. Our movement is a Catholic anti-abortion campaign that forcefully and uncompromisingly asserts the urgent need to criminalize abortion in every stage and circumstance.
        \n\nWE AFFIRM that abortion is murder and that anyone who participates in it is deserving of punishment. Furthermore, since the intrinsically evil character of abortion is of Natural Law, WE REJECT that there is any circumstance in which ignorance or any other excuse can remove culpability.`} 
      />
      <FAQItem 
        question="What is your argument against pro-choice?" 
        answer="We don’t argue with evil, we destroy it." 
      />
      <FAQItem 
        question="Who should join us?" 
        answer={`We affirm that no Catholic man can rightfully remain passive while innocent babies are being slaughtered. In fact, any sacrifice of ours will fall short of what is called for by the sheer extent and evil of this ongoing mass murder. Therefore we strongly urge ALL Catholic men to join us in immediate action, viewing this response to the most urgent crisis of our time as a call to war.
        \n\nWe also implore all Catholic women to pray for us. We exclude women from other activities partly for their own physical and emotional safety. But it is worth noting that prayer is always the most powerful thing any of us can do. Therefore, we strongly encourage all to pray the Rosary everyday and continually seek the intercession of Our Lady of Guadalupe, Patroness of the Unborn, for all pro-life movements but especially for ours.`} 
      />
      <FAQItem 
        question="Why is the movement exclusively Catholic?" 
        answer={`We affirm that receiving the Sacraments of the Church and a strong prayer life are necessary to be part of a political movement that has our intention. This is not only because we cannot win without supernatural help, but also because it is necessary to make sure our actions are perpetually aimed at heaven and NEVER on anything in this world. Without this assurance, it is impossible to hope for the grace of supernatural prudence and courage that will be needed to face the evil that we have set out to battle; because it is only in fighting for heaven that we can say, “even if we die, we win”.`} 
      />
    </div>
  );
};

export default FAQ;
