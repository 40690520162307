import React from 'react';
import FAQ from './FAQ';
import './FAQ.css';

function App() {
  return (
    <div className="App">
      <FAQ />
    </div>
  );
}

export default App;